import React from "react"
import { Link } from "gatsby"

export function ThankYou() {
  return (
    <div className="max-w-screen-lg xl:mx-auto px-8 py-5 md:px-12 sm:py-10 text-center">
      <h1 className="text-gray-900 text-4xl pt-12 pb-6 font-bold font-display leading-tight tracking-tight">
        Thank You!
      </h1>
      <p className="text-xl text-gray-800 leading-relaxed">
        You will receive an email with instructions on how to access the Discord
        server within the next 30 minutes. To get started with JavaScript
        Simplified now just use the button below.
      </p>
      <div className="my-12 text-2xl">
        <Link
          to="https://courses.webdevsimplified.com/products/home"
          className="px-6 py-4 rounded font-bold bg-blue-500 hover:bg-blue-600 text-white cursor-pointer"
        >
          Start Learning Now
        </Link>
      </div>
    </div>
  )
}
